import React, { useEffect } from 'react';
import * as d3 from "d3";
import Utils from '../../common/Utils';
import Val from '../../models/Val';
import { createTimeSeriesLine } from '../utils/D3Util';

const isVisibleOnChart = (reading) => {
    const result = !Val.isCheck(reading) && !Val.isSecondaryNoise(reading);
    console.log('isVisibleOnChart - reading:', reading, 'result:', result);
    return result;
}

const calcVisibleInspections = (point) => {
    if (point && point.valHistory) {
        const visibleInspections = point.valHistory.filter(x => isVisibleOnChart(x));
        console.log('calcVisibleInspections - point:', point, 'visibleInspections:', visibleInspections);
        return visibleInspections;
    }
    console.log('calcVisibleInspections - point:', point, 'visibleInspections: []');
    return [];
}

const PointGraph = ({ point, accent, instanceNumber = 0, width = 450, height = 250 }) => {
    console.log('PointGraph params:', { point, accent, instanceNumber, width, height });
    point = point || {};
    const data = calcVisibleInspections(point);
    console.log('Visible Inspections Data:', data);
    const chartDOMID = `chart-${point?.uuid || 'no-uuid'}-${instanceNumber}`;

    if (Utils.isBlank(data) || data.length === 0) {
        return null;
    }

    // Set up margins and inner dimensions
    const margin = { top: 20, right: 20, bottom: 50, left: 50 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // Define scales for axes
    const xScale = d3.scaleTime()
        .domain(d3.extent(data, d => Utils.timeDateStrToDate(d.recorded_at)))
        .range([0, innerWidth]);

    const yScale = d3.scaleLinear()
        .domain([
            d3.min(data, d => d.sig),
            d3.max(data, d => d.sig)
        ])
        .nice()
        .range([innerHeight, 0]);

    // Generate chart paths and shapes
    const dInfo = createTimeSeriesLine({
        data,
        accent,
        stackedData: point.measurementStats,
        width: innerWidth,
        height: innerHeight,
        pointColorFn: datum => Utils.calcSoundSigColor(datum.sig),
        dateFn: datum => Utils.timeDateStrToDate(datum.recorded_at),
        isTriangleFn: datum => datum.warningMode,
        xScale,
        yScale
    });

    const {
        path,
        symbol,
        triangle,
        star,
        circles,
        accents,
        triangles,
        greenLine,
        yellowLine,
        redLine
    } = dInfo;

    useEffect(() => {
        drawChart();
    }, []);

    const drawChart = () => {
        const svg = d3.select(`#${chartDOMID}`)
            .append("svg")
            .attr("width", width)
            .attr("height", height);

        const group = svg.append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        // Draw baseline lines if they exist
        if (greenLine) {
            group.append('path')
                .attr('d', greenLine)
                .attr('strokeWidth', 1)
                .attr('stroke', 'black')
                .attr('fill', 'none');
        }

        // if (yellowLine) {
        //     group.append('path')
        //         .attr('d', yellowLine)
        //         .attr('strokeWidth', 1)
        //         .attr('stroke', 'black')
        //         .attr('stroke-dasharray', '5,5')
        //         .attr('fill', 'none');
        // }

        // if (redLine) {
        //     group.append('path')
        //         .attr('d', redLine)
        //         .attr('strokeWidth', 1)
        //         .attr('stroke', 'black')
        //         .attr('stroke-dasharray', '5,5')
        //         .attr('fill', 'none');
        // }

        // Draw circles (points)
        group.selectAll('.circle-point')
            .data(circles)
            .enter()
            .append('path')
            .attr('d', symbol)
            .attr('opacity', 1)
            .attr('strokeWidth', 1)
            .attr('stroke', '#333333')
            .attr('fill', d => d[2])
            .attr('transform', d => `translate(${d[0]}, ${d[1]})`);

        // Draw triangles (warnings)
        group.selectAll('.triangle-point')
            .data(triangles)
            .enter()
            .append('path')
            .attr('d', triangle)
            .attr('opacity', 1)
            .attr('strokeWidth', 1)
            .attr('stroke', '#333333')
            .attr('fill', '#FFD700')
            .attr('transform', d => `translate(${d[0]}, ${d[1]})`);

        // Draw accents (stars)
        group.selectAll('.star-point')
            .data(accents)
            .enter()
            .append('path')
            .attr('d', star)
            .attr('opacity', 1)
            .attr('strokeWidth', 1)
            .attr('stroke', '#333')
            .attr('fill', '#333')
            .attr('transform', d => `translate(${d[0]}, ${d[1]})`);

        // Draw the main path line
        group.append('path')
            .attr('d', path)
            .attr('strokeWidth', 1)
            .attr('stroke', '#666666')
            .attr('fill', 'none');

        // Add axes (no x tick labels)
        const xAxis = d3.axisBottom(xScale).tickValues([]);
        // const yAxis = d3.axisLeft(yScale);
        const yAxis = d3.axisLeft(yScale).tickFormat(() => '');


        group.append('g')
            .attr('transform', `translate(0,${innerHeight})`)
            .call(xAxis);

        group.append('g')
            .call(yAxis);

        // Y-axis label
        group.append('text')
            .attr('transform', 'rotate(-90)')
            .attr('y', -margin.left + 0)
            .attr('x', -innerHeight / 2)
            .attr('dy', '0.75em')
            .style('text-anchor', 'middle')
            .attr('fill', 'blue')
            .text('SoundEnergy™ (SE)');

        // X-axis label
        group.append('text')
            .attr('x', innerWidth / 2)
            .attr('y', innerHeight + margin.bottom - 10)
            .style('text-anchor', 'middle')
            .attr('fill', 'black')
            .text('Timeline');

        // Add DST Baseline legend at bottom-left
        const legendX = -45;
        const legendY = innerHeight + margin.bottom - 10;

        group.append('line')
            .attr('x1', legendX)
            .attr('x2', legendX + 10)
            .attr('y1', legendY)
            .attr('y2', legendY)
            .attr('stroke', 'black')
            .attr('stroke-width', 2);

        group.append('text')
            .attr('x', legendX + 15)
            .attr('y', legendY + 5)
            .style('text-anchor', 'start')
            .attr('fill', 'black')
            .text('DST Baseline');
    }

    return <div id={chartDOMID}></div>;
};

export default PointGraph;
