import Model from "./Model";
import { FaSquareFull } from 'react-icons/fa';

import Utils from '../common/Utils';
import { Storage } from 'aws-amplify';
import moment from 'moment-timezone';
import Resizer from "react-image-file-resizer";


const resizeFile = (file) =>
new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    1500,
    1500,
    "JPEG",
    90,
    0,
    (uri) => {
      resolve(uri);
    },
    "base64"
  );
});

export default class Equipment extends Model {


    
    static searchByEquipmentUuid = async(equipmentUuid) => {
        return this.searchBy("uuid", equipmentUuid);
    }

    static searchByEquipmentKey = async(equipmentKey) => {
        return this.searchBy("key", equipmentKey);
    }

    static searchBy = async (key="uuid", value, query="") => {
        const vars = {
            input: {
                searchInput: {
                    query, 
                    limit: 15, 
                    filters: [ 
                        { 
                            id: value,
                            fieldName: key
                        }
                    ]
                }
            }
        }
        const res = await this.runQueryNetworkOnly(EquipmentSearch, vars);
        return res.data.rootFigSearch;
    }

    static randInt = () => {
        return parseInt(`${Math.random()}`.split(".")[1].slice(0,9));
    }

    static getAllPoints = async (equipmentUUID) => {
        const data = await super.filterBy(EquipmentPointFilter, [{
            field: "uuid",
            value: equipmentUUID,
            comparison: "="
        }], "created_at", "asc", 1);

        const result = data && data.equipmentFilter;
        return {
            data: result && (result.edges || []).map(e => e.node),
            pageInfo: result && result.pageInfo
        }
    }

    static enableNewPoints = async (fig_uuid, root_fig_uuid, pointUUIDsWithMeasurementId) => {
        
        const vars = {
            input: { 
                created_at: "2019-12-30 19:57:05", 
                root_fig_uuid, 
                fig_uuid, 
                points: pointUUIDsWithMeasurementId,
            }
        };

        console.log({vars})
        const res = await this.runMutation(EnableNewPoints, vars);
        return res.enableNewPoints;
        
    }

    static copySheet = async (fig_uuid, sheet_uuid, user_id) => {
        const vars = {
            input: {
                fig_uuid: fig_uuid,
                sheet_uuid: sheet_uuid,
                user_id: user_id,
            }
        };
    
        console.log({vars});
        const res = await this.runMutation(CopySheet, vars);
        return res.copySheet; 
    };
    

    static disablePoints = async (point_uuid) => {
        const vars = {
            uuid: point_uuid
        }
        const res = await this.runMutation(DisableExistingPoint, vars);
        console.log("res", res)
        return res.disablePoint;
    }

    static create = async(equipment) => {
        if (equipment.key === undefined){
            equipment = {
                ...equipment,
                key: this.randInt()
            }
        }
        const res = await this.runMutation(EquipmentCreate, {input: equipment});
        return res.data.createEquipment;
    }

    static createComponent = async(equipment) => {
        if (equipment.key === undefined){
            equipment = {
                ...equipment,
                key: this.randInt()
            }
        }
        const res = await this.runMutation(ComponentCreate, {input: equipment});
        console.log({res})
        return res.data.createComponent;
    }

    static update = async(fields) => {
        console.log("Updating equipment", fields); 
        if (fields.uuid === undefined || fields.uuid === null){
            console.log("Can't update equipment - no uuid specified")
            return
        }
        const res = await this.runQueryNetworkOnly(UpdateEquipment, {input: {
            uuid: fields.uuid,
            archived: fields.archived, 
            name: fields.name,
            model_num: fields.model_num,
            year: fields.year,
            serial_num: fields.serial_num,
            make: fields.make,
            make_id: fields.make_id,
            notes: fields.notes,
            comments: fields.comments,
            system_id: fields.system_id,
            criticality: fields.criticality,
            image_input: fields.image_input,
            faceplate_image_input: fields.faceplate_image_input, 
            is_valid_mpe: fields.is_valid_mpe,
            area: fields.area,
            external_uuid: fields.external_uuid,
            // key: fields.key,
            parent_key: fields.parent_key ? parseInt(fields.parent_key) : null,
            building: fields.building,
            floor: fields.floor
        }});
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ User.UpdateEquipment.runQuery [COMPLETE]`);
        console.log("res", res)
        return res.data.updateEquipment;
    }

    static filterBy = async (searchList=[], inspectionSearchList=[], sortField="created_at", sortOrder="desc", first, after, last, before, startDate, ignoreCache = false) => {
        const data = await super.filterBy(EquipmentFilter, searchList, sortField, sortOrder, first, after, last, before, ignoreCache, {
            inspectionFilter: {
                searchList: inspectionSearchList,
            }
        });
        const result = data && data.equipmentFilter;
        return {
            data: result && (result.edges || []).map(e => e.node),
            pageInfo: result && result.pageInfo
        }
    }

    static getEquipmentToEdit = async (uuid) => {
        const data = await super.filterBy(EquipmentEdit, [{ 
            value: uuid,
            field: 'uuid'
        }], 'updated_at', 'desc', 1, undefined, undefined, undefined, true);
        const result = data && data.equipmentFilter;
        return {
            data: result && (result.edges || []).map(e => e.node),
            pageInfo: result && result.pageInfo
        }
    }

    static getFigHealthScore = async(fig_uuid) => {
        const variables = { fig_uuid: fig_uuid };
        const res = await this.runQuery(GetFigHealthScore, variables);
        return res.data.FigHealthScore;
    }


    static getEquipmentToEditByKey = async (key) => {
        const data = await super.filterBy(EquipmentEdit, [{ 
            value: key,
            field: 'key'
        }], 'updated_at', 'desc', 1, undefined, undefined, undefined, true);
        const result = data && data.equipmentFilter;
        return {
            data: result && (result.edges || []).map(e => e.node),
            pageInfo: result && result.pageInfo
        }
    }

    static calcSoundSigColor = soundSig => {
        if (Utils.isBlank(soundSig) || soundSig <= 0 || soundSig === null || soundSig === undefined) {
            return "#CCCCCC"; // N/A
        }
        if (soundSig > 20) {
            return "#FF1100"; // red
        }
        if (soundSig > 15) {
            return "#FFBF00"; // amber
        }
        if (soundSig > 10) {
            return "#FFFF00"; // yellow
        }
        if (soundSig > 5) {
            return "#BFFF00"; // lime
        }
        if (soundSig >= 0) {
            return "#33CB24"; // green
        }

        return "#CCCCCC"; // N/A
    };

    static calcSoundSigColorRank = soundSig => {
        // higher = worse (red==4, amber==3, yellow==2, lime==1, green==0)
        if (Utils.isBlank(soundSig) || soundSig <= 0 || soundSig === null || soundSig === undefined) {
            return -1;
        }
        if (soundSig <= 5) {
            return 0;
        }
        if (soundSig <= 10) {
            return 1;
        }
        if (soundSig <= 15) {
            return 2;
        }
        if (soundSig <= 20) {
            return 3;
        }
        return 4;
    };

    static findByUUID = async (uuid) => {
        const results = await this.filterBy([
            {"field": "uuid", "value": `${uuid}`, "comparison": "="}
        ], [], "uuid", "asc", 1);
        return (results.data && results.data.length > 0) ? results.data[0] : [];
    }

    static findByID = async (id) => {
        const results = await this.filterBy([
            {"field": "id", "value": `${id}`, "comparison": "="}
        ], [], "id", "asc", 1);
        return (results.data && results.data.length > 0) ? results.data[0] : [];
    }

    static calcAnalysisComponentsIcon = (equipment) => {
        const {evalNumChildren, warningModeChildren} = equipment;
        if (warningModeChildren){
          return <img src="/icons/red-stop-sign.svg" style={{width: "25px", height: "25px"}}/>
        }
        const color = this.calcSoundSigColor(evalNumChildren);
        return (<FaSquareFull color={color}></FaSquareFull>)
    }
    
    static calcAnalysisComponentWarningValue = (equipment) => {
        const { warningModeLocal, warningModeChildren} = equipment;
        if (warningModeLocal || warningModeChildren){
            return 1
        }
        return 0;
    }
    static calcAnalysisComponentEvaluationValue = (equipment) => {
        const {evalNumChildren, evalNumLocal, evalNum} = equipment;
        const mergedEvalNum = _.max([evalNumLocal, evalNumChildren, evalNum]);
        return this.calcSoundSigColorRank(mergedEvalNum);
    }

    static calcAnalysisComponentIcon = (equipment) => {
        const {evalNumChildren, evalNumLocal, evalNum, warningModeLocal, warningModeChildren} = equipment;
        if (warningModeLocal || warningModeChildren){
            return <img src="/icons/red-stop-sign.svg" style={{width: "25px", height: "25px"}}/>
        }
        const mergedEvalNum = _.max([evalNumLocal, evalNumChildren, evalNum]);
        const color = this.calcSoundSigColor(mergedEvalNum);
        return (<FaSquareFull color={color}></FaSquareFull>)
    }

    static calcAnalysisPointIcon = (point) => {
        const {evalNum, warningMode} = point;
        if (warningMode){
            return <img src="/icons/red-stop-sign.svg" style={{width: "25px", height: "25px"}}/>
        }
        const color = this.calcSoundSigColor(evalNum);
        return (<FaSquareFull color={color}></FaSquareFull>)
    }

    static calcAnalysisPointsIcon = (equipment) => {
        const {evalNumLocal, warningModeLocal} = equipment;
        if (warningModeLocal){
            return <img src="/icons/red-stop-sign.svg" style={{width: "25px", height: "25px"}}/>
        }
        const color = this.calcSoundSigColor(evalNumLocal);
        return (<FaSquareFull color={color}></FaSquareFull>)
    }

    // static getFigEvalNum = (component) => {
    //     console.log("This is getFigEvalNum ", component, component.name);

    //     if (Utils.isBlank(component.evaluations) || component.evaluations.length === 0){
    //         return null;
    //     }
    //     const sorted = component.evaluations.sort((a, b) => {
    //         return moment(b.ended_at) - moment(a.ended_at);
    //     });
    //     console.log("sorted", sorted, component.name);
    //     return sorted[0].level * component.criticality;
    // }

    static getFigEvalNum = (component) => {
        // Collect the most recent evaluation from the component and its subcomponents
        let allEvaluations = [];
        const collectMostRecentEvaluation = (comp) => {
          if (comp.evaluations && comp.evaluations.length > 0) {
            const sorted = comp.evaluations.sort((a, b) => moment(b.ended_at) - moment(a.ended_at));
            allEvaluations.push({ evaluation: sorted[0], criticality: comp.criticality });
          }
        };
    
        // Collect evaluations from the main component
        collectMostRecentEvaluation(component);
    
        // Collect evaluations from subcomponents
        if (component.allSubcomponents) {
          component.allSubcomponents.forEach(subcomponent => {
            collectMostRecentEvaluation(subcomponent);
          });
        }
    
        if (allEvaluations.length === 0) {
          return null;
        }
    
        // Find the evaluation with the highest level
        const highestLevelEvaluation = allEvaluations.reduce((maxEval, currentEval) => {
          return (currentEval.evaluation.level > maxEval.evaluation.level) ? currentEval : maxEval;
        });
    
        return highestLevelEvaluation.evaluation.level * highestLevelEvaluation.criticality;
      }
    

    static calcAnalysisEvalIcon = (component) => {
        if (component){
            const evalNum = this.getFigEvalNum(component);
            const color = this.calcSoundSigColor(evalNum);
            return (<FaSquareFull color={color}></FaSquareFull>)
        }
        return null;
    }

    static updatePhotoFromAlreadyUploadedFile = async (equipmentUUID, fileName, contentType, uploadFlag, equipment, justBase64) => {
        var res; 
        if (uploadFlag){
                       
            // res = await this.runQueryNetworkOnly(UpdateEquipmentPhoto, {input: {
            //     uuid: equipmentUUID,
            //     faceplate_image_input: {
            //         mimeType: contentType,
            //         key: `mobile-photos/${fileName}`, 
            //         bucket: "dst-tmp", 
            //         region: "us-west-2",
    
            //     }
            // }});

        
        

          let headersList = {
            "Accept": "*/*",
            "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            "Content-Type": "application/json"
           }
            
           let bodyContent = JSON.stringify({
             "base64Image": `${justBase64}`
           
           });
           let response; 
           try{
                    response = await fetch("https://5rymsnzzemuqvewgglog5bbkz40hlcjz.lambda-url.us-west-2.on.aws/", {
                    method: "POST",
                    body: bodyContent,
                    headers: headersList
                  });
            }catch(err) {
                alert("There was an error trying to get response from OCR Api");
            }
            if (response.ok){
                //convert to json
                let data = await response.text();
                let json = JSON.parse(data);
                console.log('json', json);
                // Update equipment with new model, make, serial number

                res = await this.runQueryNetworkOnly(UpdateEquipmentPhoto, {input: {
                    uuid: equipmentUUID,
                    faceplate_image_input: {
                        mimeType: contentType,
                        key: `mobile-photos/${fileName}`, 
                        bucket: "dst-tmp", 
                        region: "us-west-2",
        
                    },
                    model_num: json.mn, 
                    make_id: json.make_id, 
                    serial_num: json.sn, 

                }});
                equipment.faceplate_image = {
                    bucket: "dst-photos",
                    key: `${fileName}`,
                    region: 'us-west-2',
                  };

                
            } else{
                res = await this.runQueryNetworkOnly(UpdateEquipmentPhoto, {input: {
                    uuid: equipmentUUID,
                    faceplate_image_input: {
                        mimeType: contentType,
                        key: `mobile-photos/${fileName}`, 
                        bucket: "dst-tmp", 
                        region: "us-west-2",
                    }
                }});
                equipment.faceplate_image = {
                    bucket: "dst-photos",
                    key: `${fileName}`,
                    region: 'us-west-2',
                  };
            }
        } else{
             res = await this.runQueryNetworkOnly(UpdateEquipmentPhoto, {input: {
                uuid: equipmentUUID,
                image_input: {
                    mimeType: contentType,
                    key: `mobile-photos/${fileName}`, 
                    bucket: "dst-tmp", 
                    region: "us-west-2",
                }
            }});
            equipment.image = {
                bucket: "dst-photos",
                key: `${fileName}`,
                region: 'us-west-2',
              }
        }
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ User.updateProperty.runQuery [COMPLETE]`);
        console.log("res", res)
        return res;
    }

    static updatePhoto = async (equipmentUUID, photoDataURI, photoFileMetadata, uploadFlag, equipment) => {
        if(photoFileMetadata === ''){
            alert("Please upload images");
            return;
        }

        console.log('photoDataURI', photoDataURI);
        console.log('photoFileMetadata', photoFileMetadata);
        
        var fileName; 
        const uuid = Utils.uuid();
        // If upload flag is true, this indicates a nameplate upload
        if(uploadFlag){
            console.log("IN THE FLAG TO UPLOAD NAMEPLATE");
            fileName = `${equipmentUUID}_nameplate`; 
        }else {
            console.log("iN THE FLAG TO UPLOAD EQUIPMENT PHOTO");
            fileName = `${equipmentUUID}`
        }
        const extension = photoFileMetadata.name.split('.').pop() || 'jpg';
       
        let resizedImage = await resizeFile(photoFileMetadata);
        //const split = photoDataURI.split(',');
        const split = resizedImage.split(',');
        const justBase64 = split[split.length - 1]
        const uInt8Array = Buffer.from(justBase64, 'base64')
        const buffer = Buffer(uInt8Array, 'binary')
        const customPrefix = {
            public: 'mobile-photos/',
            protected: 'mobile-photos/',
            private: 'mobile-photos/'
        };
        const contentType  = photoFileMetadata.type || `image/${extension}`;
        const result = await Storage.put(fileName, buffer, {customPrefix, contentType, level: "public", acl: "public-read"});
        console.log('fileName', fileName);
        console.log('customPrefix',customPrefix);
        console.log('contentType', contentType);
        const uploadedFilename = result.key;
        return this.updatePhotoFromAlreadyUploadedFile(equipmentUUID, uploadedFilename, contentType, uploadFlag, equipment, justBase64);
    }
}



const UpdateEquipmentPhoto = `
mutation updateEquipment($input: UpdateEquipmentInput){
    updateEquipment(input: $input){
      id
    }
  }
`

const UpdateEquipment = `
mutation updateEquipment($input: UpdateEquipmentInput){
    updateEquipment(input: $input){
        id
        updated_at
        created_at
        uuid
        archived
        name
        sheet_uuid
        sheet_option
        year
        key
        parent_key
        property_uuid
        property_id
        model_num
        serial_num
        make
        make_id
        notes
        comments
        system_id
        criticality
        max
        area
        external_uuid
    }
  }
`

const EquipmentSearch = `
    query EquipmentSearch($input:RootFigSearchInput){
        rootFigSearch(input: $input){
            id
            uuid
            name
        }
    }
`;


// large_720_img{
//     key
//     bucket
//     region
// }
// med_240_img{
//     key
//     bucket
//     region
// }
const EquipmentFilter = `
query EquipmentFilter($input: FilterConnectionInput,
    $inspectionFilter: FilterConnectionInput,
    $routineUuid: ID){
    equipmentFilter(input: $input){
        pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPrevPage
            pageCount
            totalCount
            startIndex
            endIndex
          }
      edges {
        node {
            id
            archived
            updated_at
            created_at
            uuid
            name
            building
            floor
            evaluations{
                id
            }
            sheet_uuid
            sheet_option
            year
            key
            parent_key
            property_uuid
            property_id
            lastValUploaded {
                recorded_at
                uuid
            }
            image{
                key
                bucket
                region
            }
            faceplate_image {
                bucket
                key
            }
            

         
            evalNum(routineUuid: $routineUuid, useComponentEval: true)
            evalNumLocal(routineUuid: $routineUuid)
            evalNumChildren(routineUuid: $routineUuid)
            warningModeLocal(routineUuid: $routineUuid){
                id
                recorded_at
                overb
                reviewed_at
            }
            warningModeChildren(routineUuid: $routineUuid){
                id
                recorded_at
                overb
                reviewed_at
            }
            vals(input: $inspectionFilter){
                edges{
                    node{
                      recorded_at
                      uuid
                      point_uuid
                      point_num
                      measurement_name
                    }
                }
                totalCount
            }
            checks(input: $inspectionFilter){
                edges{
                    node{
                        created_at
                        uuid
                        task_uuid
                        type_code
                        fig_uuid
                        name
                    }
                }
                totalCount
            }
            model_num
            serial_num
            make
            make_id
            is_valid_mpe
            notes
            comments
            system_id
            system {
                name
            }
            criticality
            max
            area
            external_uuid
            pointInfos {
                uuid
                evalNum
                parent_fig_name
                measurement_id
                num
                fig_uuid
                created_at
                updated_at
                measurement_name
                measurement_sort_idx
                measurement_non_sound
            }
            sheets {
                id
                uuid
                global
                company_id
                property_uuid
                system_type_id
                system_id
                name
                tasks {
                    id
                    created_at
                    uuid
                    name
                    sheet_uuid
                    type_code
                    section_uuid
                }
                sections {
                    id
                    uuid
                    name
                    sheet_uuid
                }
            }
            allSubcomponents {
                id
                is_valid_mpe
                uuid
                created_at
                key
                parent_key
                name
                updated_at
                system_id
                make
                notes
                year
                model_num
                serial_num
                criticality
                evaluations{
                    id
                    created_at
                    uuid
                    fig_id
                    started_at
                    ended_at
                    level
                    sound_level
                    comments
                    rec
                    net_samples_uuid
                    recorded_yhat_json
                    hash
                    user_id
                }
                pointInfos {
                    uuid
                    evalNum
                    parent_fig_name
                    measurement_id
                    fig_uuid
                    num
                    created_at
                    updated_at
                    measurement_name
                    measurement_sort_idx
                    measurement_non_sound
                }
                vals(input: $inspectionFilter){
                    edges{
                        node{
                          recorded_at
                          uuid
                          point_uuid
                          point_num
                          measurement_name
                        }
                    }
                    totalCount
                }
                checks(input: $inspectionFilter){
                    edges{
                        node{
                            created_at
                            uuid
                            task_uuid
                            type_code
                            fig_uuid
                            name
                        }
                    }
                    totalCount
                }
            }
            points {
                uuid
                num
                measurement{
                    name
                    sort_idx
                    id
                }
                evalNum
                
                warningMode{
                    id
                    recorded_at
                    overb
                    reviewed_at
                }
            }
            evaluations{
                created_at
                id
                uuid
                fig_id
                started_at
                ended_at
                level
                sound_level
                comments
                rec
                net_samples_uuid
                recorded_yhat_json
                hash
                user_id
            }
        }
      }
    }
  }
`

const EquipmentPointFilter = `
query EquipmentFilter($input: FilterConnectionInput){
    equipmentFilter(input: $input){
        pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPrevPage
            pageCount
            totalCount
            startIndex
            endIndex
          }
      edges {
        node {
            id
            archived
            updated_at
            created_at
            uuid
            name
            system_id
            points {
                uuid
                num
                system_id
                measurement{
                    name
                    sort_idx
                    id
                }
            }
        }
      }
    }
  }
`

const EquipmentEdit = `
query EquipmentFilter($input: FilterConnectionInput){
    equipmentFilter(input: $input){
        pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPrevPage
            pageCount
            totalCount
            startIndex
            endIndex
          }
      edges {
        node {
          id
          updated_at
          created_at
            uuid
            name
            sheet_uuid
            sheet_option
            year
            key
            parent_key
            parentFig {
                id
                uuid
                name
            }
            property_uuid
            property_id
            model_num
            serial_num
            make
            make_id
            notes
            comments
            system_id
            criticality
            max
            area
            external_uuid
            pointInfos {
                uuid
                evalNum
                parent_fig_name
                measurement_id
                num
                created_at
                updated_at
                measurement_name
                measurement_sort_idx
                measurement_non_sound
                image {
                    bucket
                    key
                }
            }
            faceplate_image {
                bucket
                key
            }
            
           
          
            image{
                key
                bucket
                region
            }
            system{
                id
                name
                measurements{
                    id
                    measurement {
                        id
                        name
                        sort_idx
                        updated_at
                    }
                }
            }
            property{
                name
            }
        }
      }
    }
  }
`
//                         sort_idx

const EnableNewPoints = `
mutation EnablePoints($input: NewPointsInput){
	enableNewPoints(input: $input){
        uuid
    }
}
`

const CopySheet = `
mutation CopySheet($input: CopySheetInput){
	copySheet(input: $input)
}
`;

const DisableExistingPoint = `
mutation DisablePoint($uuid: ID){
    disablePoint(uuid: $uuid){
        uuid
    }
}`

const EquipmentCreate = `
mutation CreateEquipment($input:EquipmentInput){
	createEquipment(input: $input){
        id
        uuid
        key
        name
        allSubcomponents {
            id
            uuid
            key
            name
        }
    }
}
`;

const ComponentCreate = `
mutation CreateComponent($input:ComponentInput){
	createComponent(input: $input){
        id
        uuid
        key
        name
        allSubcomponents {
            id
            uuid
            key
            name
        }
    }
}
`;


const GetFigHealthScore = `
query GetFigHealthScore($fig_uuid: ID!) {
    FigHealthScore(fig_uuid: $fig_uuid) {
       id
       company_id
       property_uuid
       fig_uuid
       started_at
       ended_at
      created_at
      score
    }
}`