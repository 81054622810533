import Model from "./Model";

class MultipointEvaluation extends Model{
    // static async createMultipointFromReadings(fields) {
    //     const { uuids, staging = false } = fields;
    //     console.log("In createMultipointFromReadings", fields);
    
    //     if (!Array.isArray(uuids) || uuids.length === 0) {
    //         console.log("ERRROR ERRROR");
    //         return null;
    //     }
    
    //     const res = await this.runMutation(MultipointCreateFromReadings, { uuids, staging });
    //     console.log("This is the res from createMultipointFromReadings", res); 
    //     return res.data.createMultipointFromReadings;
    // }

    static async createMultipointFromProperty(property_uuid, start_date, end_date, user_id, createEvaluations) {
        const variables = {
            property_uuid,
            start_date,
            end_date,
            user_id,
            createEvaluations
        };
        const res = await this.runMutation(MultipointCreateFromProperty, variables);
        console.log("res from CreateMultipointFromProperty", res);
        return res.data.createMultipointFromProperty;
    }

    static async createMultipointFromEquipment(equipment_uuids, start_date, end_date, user_id, createEvaluations) {
        const variables = {
            equipment_uuids,
            start_date,
            end_date,
            user_id,
            createEvaluations
        };
        const res = await this.runMutation(MultipointCreateFromEquipment, variables);
        return res.data.createMultipointFromEquipment;
    }


    static async validMultipointFromProperty(property_uuid, start_date, end_date) {
        const variables = {
            property_uuid,
            start_date,
            end_date
        };
        const res = await this.runQuery(ValidMultipointFromProperty, variables);
        return res.data.validMultipointFromProperty;
    }

    static async validMultipointFromEquipment(equipment_uuids, start_date, end_date) {
        const variables = {
            equipment_uuids,
            start_date,
            end_date
        };
        const res = await this.runQuery(ValidMultipointFromEquipment, variables);
        return res.data.validMultipointFromEquipment;
    }

    static async createMultipointFromReadings(readingGroups, staging=false) {
        console.log("In createMultipointFromReadings", {readingGroups, staging});
        if (!Array.isArray(readingGroups) || readingGroups.length === 0) {
            console.log("ERROR: Invalid input");
            return null;
        }

        console.log("Running mutation with variables:", { readingGroups, staging });
        const res = await this.runMutation(MultipointCreateFromReadings, { readingGroups, staging });      
        console.log("This is the res from createMultipointFromReadings", res);
        return res.data.createMultipointFromReadings;
    }

    static async validMultipointFromReadings(readingGroups) {
        if (!Array.isArray(readingGroups) || readingGroups.length === 0) {
            console.log("ERROR: Invalid input");
            return false;
        }
        const res = await this.runMutation(ValidMultipointFromReadings, { readingGroups });
        return res.data.validMultipointFromReadings;
    }


    static async saveMultipointFromReadings(actionUuids, tagUuids, evalLevel, net_samples_uuid, fig_uuid) {
        const vars = {
            input:{
                actionUuids: actionUuids,
                tagUuids: tagUuids,
                evalLevel: evalLevel,
                net_samples_uuid: net_samples_uuid,
                fig_uuid: fig_uuid, 

            }
            
        };

        const res = await this.runQueryNetworkOnly(MultipointCreateFromReadingsPlatform, vars);

        return res.data.createNetSamplePlatform;
    }

    static filterBy = async (searchList=[], sortField="created_at", sortOrder="desc", first, after, last, before, ignoreCache) => {
        const data = await super.filterBy(NetSampleFilter, searchList, sortField, sortOrder, first, after, last, before, ignoreCache, {});
        const result = data && data.netSampleFilter;
        console.log("Result from filterBy", result);
        return {
            data: result && (result.edges || []).map(e => e.node),
            pageInfo: result && result.pageInfo
        }
    }

      static filterActionsBy = async (searchList=[], sortField="created_at", sortOrder="desc", first, after, last, before, ignoreCache) => {
        const data = await super.filterBy(FigActionsFilter, searchList, sortField, sortOrder, first, after, last, before, ignoreCache, {});
        const result = data && data.figActionsFilter;
        return {
            data: result && (result.edges || []).map(e => e.node),
            pageInfo: result && result.pageInfo
        }
    }

      static filterTagsBy = async (searchList=[], sortField="created_at", sortOrder="desc", first, after, last, before, ignoreCache) => {
        const data = await super.filterBy(FigTagsFilter, searchList, sortField, sortOrder, first, after, last, before, ignoreCache, {});
        const result = data && data.figTagsFilter;
        return {
            data: result && (result.edges || []).map(e => e.node),
            pageInfo: result && result.pageInfo
        }
    }
   
    static findByUUID = async (uuid) => {
        const results = await this.filterBy([
            {"field": "uuid", "value": uuid, "comparison": "="}
        ], "created_at", "desc", 10000, undefined, undefined, undefined, true);
        return (results.data && results.data.length > 0) ? results.data : [];
    }

      static findActionsByUUID = async (uuid) => {
        const results = await this.filterActionsBy([
            {"field": "uuid", "value": uuid, "comparison": "="}
        ], "created_at", "desc", 10000, undefined, undefined, undefined, true);
        return (results.data && results.data.length > 0) ? results.data : [];
    }

      static findTagsByUUID = async (uuid) => {
        const results = await this.filterTagsBy([
            {"field": "uuid", "value": uuid, "comparison": "="}
        ], "created_at", "desc", 10000, undefined, undefined, undefined, true);
        return (results.data && results.data.length > 0) ? results.data : [];
    }

}

export default MultipointEvaluation;

const MultipointCreateFromReadings = `
mutation CreateMultipointFromReadings($readingGroups: [ReadingGroupInput]!, $staging: Boolean) {
    createMultipointFromReadings(readingGroups: $readingGroups, staging: $staging) {
        uuid
        recordedYHat {
            nodes {
                evalLevel
                tagUuids
                actionUuids
            }
        }
        x {
            nodes {
                makeUuid
                modelNum
                readings {
                    se
                    evalLevel
                    actionUuids
                    signatureUuids
                    measurementUuid
                }
                systemUuid
            }
        }
    }
}`;


const ValidMultipointFromReadings = `
query ValidMultipointFromReadings($readingGroups: [ReadingGroupInput]!) {
    validMultipointFromReadings(readingGroups: $readingGroups)
}`;

const MultipointCreateFromReadingsPlatform = `
mutation  CreateNetSamplePlatform($input: NetSamplePlatformInput){
    createNetSamplePlatform(input: $input)      
}`;



const GQL_FULL_INSPECTION_FRAGMENT = `{
    uuid
    created_at
    updated_at
    name
    context
    multiplier
    notes
    feature_filter_uuid
    x_checksum
    xy_checksum
    y_checksum
    x_json
    y_json
    recorded_yhat_json
    archived
    tf1
    tf2
    tf3
    tf4
    tf5
}`

const NetSampleFilter = `
query NetSampleFilter($input: FilterConnectionInput){
    netSampleFilter(input: $input){
        pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPrevPage
            pageCount
            totalCount
            startIndex
            endIndex
          }
      edges {
        node 
            ${GQL_FULL_INSPECTION_FRAGMENT}
      }
    }
  }
`

const GQL_FULL_INSPECTION_FRAGMENT_2 = `{
    id
    created_at
    updated_at
    uuid
    name
    code
}`

const FigActionsFilter = `
query FigActionsFilter($input: FilterConnectionInput){
    figActionsFilter(input: $input){
        pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPrevPage
            pageCount
            totalCount
            startIndex
            endIndex
          }
      edges {
        node 
            ${GQL_FULL_INSPECTION_FRAGMENT_2}
      }
    }
  }
`

const FigTagsFilter = `
query FigTagsFilter($input: FilterConnectionInput){
    figTagsFilter(input: $input){
        pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPrevPage
            pageCount
            totalCount
            startIndex
            endIndex
          }
      edges {
        node 
            ${GQL_FULL_INSPECTION_FRAGMENT_2}
      }
    }
  }
`

const MultipointCreateFromProperty = `
mutation CreateMultipointFromProperty($property_uuid: ID!, $start_date: String!, $end_date: String!, $user_id: ID!, $createEvaluations: Boolean) {
    createMultipointFromProperty(property_uuid: $property_uuid, start_date: $start_date, end_date: $end_date, user_id: $user_id, createEvaluations: $createEvaluations)
}`;

const MultipointCreateFromEquipment = `
mutation CreateMultipointFromEquipment($equipment_uuids: [ID]!, $start_date: String!, $end_date: String!, $user_id: ID!, $createEvaluations: Boolean) {
    createMultipointFromEquipment(equipment_uuids: $equipment_uuids, start_date: $start_date, end_date: $end_date, user_id: $user_id, createEvaluations: $createEvaluations)
}`;

const ValidMultipointFromProperty = `
query ValidMultipointFromProperty($property_uuid: ID!, $start_date: String!, $end_date: String!) {
    validMultipointFromProperty(property_uuid: $property_uuid, start_date: $start_date, end_date: $end_date) {
        property_uuid
        equipmentValidity {
            equipment_uuid
            isValid
        }
    }
}`;

const ValidMultipointFromEquipment = `
query ValidMultipointFromEquipment($equipment_uuids: [ID]!, $start_date: String!, $end_date: String!) {
    validMultipointFromEquipment(equipment_uuids: $equipment_uuids, start_date: $start_date, end_date: $end_date) {
        property_uuid
        equipmentValidity {
            equipment_uuid
            isValid
        }
    }
}`;