import { useEffect, useState, useContext } from 'react';
import { useAuth } from '../context/AuthContext';
import Company from "../models/Company";
import Property from "../models/Property";
import Equipment from "../models/Equipment";
import Systems from "../models/Systems";
import { withTheme } from '@rjsf/core';
import { Link } from "react-router-dom";
import { Theme as Bootstrap4Theme } from '../theme/bootstrap-4';
const Form = withTheme(Bootstrap4Theme);
import Utils from "../common/Utils";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import XLSX from 'xlsx';
import jsonpath from 'JSONPath';
import jsonTransform from 'jsonpath-object-transform';
import {NavSidebar} from './components/NavSidebar';
import _ from 'lodash';
import { ImSpinner3 } from 'react-icons/im';
import PredictSystemTypeId from '../queries/PredictSystemTypeId';
import QueryContext from '../context/QueryContext';
import {Validator} from 'jsonschema';
import {FaExclamationTriangle} from 'react-icons/fa';


const ImportScreen = () => {
    const {
        user,
        isAdminOrHigher,
        isSuperAdmin
    } = useAuth();

    const [uploadedFileJSON, setUploadedFileJSON] = useState({});
    const [uploadedFileJSONImport, setUploadedFileJSONImport] = useState({});
    
    let fileReader = null;
    const [loadingFile, setLoadingFile] = useState(false);
    const [loadedFile, setLoadedFile] = useState(false);
    const [systemNameLookup, setSystemNameLookup] = useState({});
    const [systemTypeIdLookup, setSystemTypeIdLookup] = useState({});
    const [systemIdLookup, setSystemIdLookup] = useState({});
    const [companies, setCompanies] = useState([]);
    const disableImport = false;
    const [disableNewCompany, setDisableNewCompany] = useState(false);
    const [manualEditMode, setManualEditMode] = useState(false);
    const { runQuery } = useContext(QueryContext);
    const [predictionsCompleted, setPredictionsCompleted] = useState(false);
    const [predictSystemTypes, setPredictSystemTypes] = useState(false);
    const [errorLog, setErrorLog] = useState([]);
    const COMPONENT_DEPTH_LIMIT = 1;
    
    useEffect(() => {
        updateCompanies();
        updateUserSystems();
        // console.log(systemsIdLookup)
    }, [predictSystemTypes])

    useEffect(() => {
        setErrorLog(validateJson(uploadedFileJSON));
    }, [uploadedFileJSON])

    const validateJson = (uploadedFileJSON) => {
        if (!uploadedFileJSON || !uploadedFileJSON.companies){
            return []; // empty json = no errors
        }

        let newErrorLog = [];

        // Check for dupe root equipment names
        let rootEquipmentNames = []
        let missingSystemType = []
        uploadedFileJSON.companies.map((c, ci) => 
            (c.properties || []).map((p, pi) => {
                (p.equipments || []).map((e, ei) => {
                    rootEquipmentNames = [...rootEquipmentNames, `${p.name} ⮕ ${e.name}`] // only names within a property are checked for duplicates
                    e.system_name = e.system_name ? e.system_name.trim() : e.system_name;
                    if (!e.system_name){
                        missingSystemType = [...missingSystemType, e.name];
                    }
                    (e.subcomponents || []).map(c => {
                        c.system_name = c.system_name ? c.system_name.trim() : c.system_name;
                        if (!c.system_name){
                            missingSystemType = [...missingSystemType, c.name];
                        }
                    })
                })
            })
        )
        
        const dupes = _.filter(rootEquipmentNames, (val, i, iteratee) => _.includes(iteratee, val, i + 1));
        if (dupes.length > 0){
            newErrorLog  = [...newErrorLog, {message: `Multiple equipment have the same name, please update these and re-upload. Equipment: ${_.uniq(dupes).map(d => d.split(' ⮕ ').slice(1)).sort().join(',  ')}`}]
        }
        if (missingSystemType.length > 0){
            newErrorLog  = [...newErrorLog, {message: `Some equipment lack a valid system type, please update these and re-upload. Equipment: ${missingSystemType.join(', ')}`}]
        }

        // Validate against the form's schema
        const v = new Validator();
        const validation = v.validate(uploadedFileJSON, validationSchema());
        console.log(validation)
        const enumErrors = 
            validation.errors
            .filter(e => e.name === "enum")
            .map(e => e.instance)
            
        const validationErrors = validation.errors.filter(e => e.name !== "enum")
        .map(e => e.name === "pattern" ? {...e, message: 'does not match expected pattern, please verify that this is correct.'} : e)
        .map(e => {
            return {message: `${e.schema.title} (${e.instance}): ${e.message}`}
        })

        if (enumErrors.length > 0){
            validationErrors.push({
                message: `The following systems were not found in the system list: ${[...new Set(enumErrors)].map(e => `"${e}"`).join(', ')}. Expected one of the following: ${Object.keys(systemNameLookup).filter(e => e.length > 0).join(", ")}. Check if the system is enabled for this company.`
            })
        }


        return [...newErrorLog, ...validationErrors];
    }

    const updateCompanies = async () => {
        const allCompanies = await Company.all();
        if (isSuperAdmin()){
            setCompanies(allCompanies);
        }else if (isAdminOrHigher()){
            // Limit to currently selected company
            setCompanies(allCompanies.filter(c => c.uuid === (user.company && user.company.uuid)))
            setDisableNewCompany(true);
        }
    }

    const useSystemIdsAsKeys = async (systemsDictionary, key = 'system_id') => {
        let newSystemsDictionary = {}
        for (let systemName in systemsDictionary) {
            let systemInfoObj = systemsDictionary[systemName]
            if (!(systemInfoObj.hasOwnProperty(systemInfoObj['system_type_id']))) {
                newSystemsDictionary[systemInfoObj['system_type_id']] = systemInfoObj
            }
        }
        return newSystemsDictionary;
    }

    const updateUserSystems = async () => {
        const newSystemsDictionary = await Systems.getSystemsForCompanyId(user.company_id)
        // include "empty" system
        setSystemNameLookup({"":{}, ...newSystemsDictionary});
        const newSystemTypeIdDictionary = await useSystemIdsAsKeys(newSystemsDictionary, 'system_type_id')
        setSystemTypeIdLookup({"":{}, ...newSystemTypeIdDictionary});

        const newSystemsIdDictionary = await useSystemIdsAsKeys(newSystemsDictionary, 'system_id')
        setSystemIdLookup({"":{}, ...newSystemsIdDictionary});
    }
    
    const importCompany = async (company) => {
        console.log("company   Details", JSON.stringify(company))
        const matchedCompanies = await Company.searchByCompanyTitle(company.name);
        const exists = matchedCompanies.length >= 1;
        console.log("Company already exists?", exists ? "yes" : "no")
        if (!exists) {
            const newCompany = {
                "uuid": company.uuid || Utils.uuid(),
                "name": company.name.replace(/[^0-9a-z]/gi, '').toLowerCase(),
                "title": company.title || company.name,
                "external_uuid": company.external_uuid
            };
            console.log("newCompany", newCompany);
            if (disableImport) return null;
            const createdCompany = await Company.create(newCompany);
            return createdCompany;
        }
        //update existing company
        return matchedCompanies.length === 1 ? matchedCompanies[0] : null;
    }

    const importProperty = async (company, property) => {
        const matchedProperties = await Property.searchByPropertyUuid(property.uuid);
        const exists = matchedProperties.length >= 1;
        console.log("Property already exists?", exists ? "yes" : "no")
        if (!exists) {
            const newProperty = {
                "company_id": company.id,
                "uuid": property.uuid || Utils.uuid(),
                "name": property.name,
                "street1": property.street1,
                "street2": property.street2,
                "city": property.city,
                "state": property.state,
                "country": property.country || "United States",
                "zip": property.zip,
                "note": property.note,
                "external_uuid": property.external_uuid
            }
            //update existing property
            console.log("newProperty", newProperty);
            if (disableImport) return null;
            const createdProperty = await Property.create(newProperty);
            return createdProperty;
        }
        return matchedProperties.length === 1 ? matchedProperties[0] : null;
    }

    const importEquipment = async (property, equipment, isComponent = false) => {
        const matchedEquipment = await Equipment.searchByEquipmentUuid(equipment.uuid);
        const exists = matchedEquipment.length >= 1;
        // console.log("Equipment already exists?", exists ? "yes" : "no")
        if (!exists) {

            if (predictSystemTypes && (!equipment.system_name)) {
                const predictedSystemTypeId = await getPredictedSystemTypeId(equipment);
                equipment.system_id = systemTypeIdLookup[predictedSystemTypeId.system_type_id].system_id;
                console.log("predicted system type name", systemTypeIdLookup[predictedSystemTypeId.system_type_id].name)
            } else if (!equipment.system_name) {
                equipment.system_id = "";
            } else {
                equipment.system_id = systemNameLookup[equipment.system_name].system_id;
            }


            const newEquipment = {
                "property_id": property.id,
                "property_uuid": property.uuid,
                "uuid": equipment.uuid || Utils.uuid(),
                "name": equipment.name,
                "model_num": equipment.model_num,
                "serial_num": equipment.serial_num,
                "notes": equipment.notes,
                "make": equipment.make,
                "system_id": equipment.system_id,
                "criticality": equipment.criticality,
                "year": Number.parseInt(equipment.year),
                "external_uuid": equipment.external_uuid,
                "unmapped_json_import": JSON.stringify(equipment.unmapped_json_import),
                "building": equipment.building,
                "floor": equipment.floor,
                "area": equipment.area,
                "parent_key": equipment.parent_key
            }
            // console.log("newEquipment", newEquipment);
            if (disableImport) return null;

            if (isComponent){
                return await Equipment.createComponent(newEquipment);
            }else{
                return await Equipment.create(newEquipment);
            }
        }
        //update existing equipment
        return null;
    }

    const parseAndImport = async (json) => {
        // console.log(JSON.stringify(json))
        let createdCompanies = [];
        let createdProperties = [];
        let createdEquipments = [];

        if (json.hasOwnProperty("companies")) {
            // Import all companies (if they don't already exist)
            for (let ci = 0; ci < json.companies.length; ci++) {
                const company = json.companies[ci];
                const newCompany = await importCompany(company.companyDetails);
                // console.log("newCompany", newCompany)
                if (newCompany) {
                    createdCompanies.push(newCompany);
                }

                if (company.hasOwnProperty("properties")) {
                    // Import company's properties
                    for (let pi = 0; pi < company.properties.length; pi++) {
                        const property = company.properties[pi];
                        const newProperty = await importProperty(newCompany, property);
                        
                        // console.log("newProperty", newProperty)
                        if (newProperty) {
                            createdProperties.push(newProperty);
                        }
                        
                        if (property.hasOwnProperty("equipments")) {
                            toast.info(
                                `Importing ${property.equipments.length} equipment for ${property.name} property`,
                                {autoClose: 5000}
                            );
                            //Import property's equipment
                            for (let ei = 0; ei < property.equipments.length; ei++) {
                                let equipment = property.equipments[ei];
                                equipment.system_name = equipment.system_name ? equipment.system_name.trim() : equipment.system_name;
                                const newEquipment = await importEquipment(newProperty, equipment);
                                if (newEquipment) {
                                    createdEquipments.push(newEquipment);
                                }
                                //Import equipment's subcomponents (one level supported so far)
                                for (let ci = 0; ci < equipment.subcomponents.length; ci++) {
                                    let component = equipment.subcomponents[ci];
                                    component.system_name = component.system_name ? component.system_name.trim() : component.system_name;
                                    component.parent_key = newEquipment.key;
                                    console.log("component", component)
                                    const newComponent = await importEquipment(newProperty, component, true);
                                    if (newComponent) {
                                        createdEquipments.push(newComponent);
                                    }
                                }
                            }
                        }
                    }
                }
                toast.info(
                    `Imported all ${(newCompany.name || "").toUpperCase()} properties`,
                    {autoClose: 5000}
                );
            }
        }
        console.log("createdCompanies", createdCompanies.length, createdCompanies)
        console.log("createdProperties", createdProperties.length, createdProperties)
        console.log("createdEquipments", createdEquipments.length, createdEquipments)

        console.log(`Created the following: ${JSON.stringify([createdCompanies, createdProperties, createdEquipments], null, 4)}`);
        
        if (createdCompanies.length > 0 || createdProperties.length > 0 || createdEquipments.length > 0){
            return [createdCompanies, createdProperties, createdEquipments];
        }
        return [];
    }

    const reformatBluefinJSON = (json) => {
        const template = {
            companies: ['$.Contractors', {
                companyDetails: {
                    name: '$.name',
                    external_uuid: '$.uuid'
                },
                properties: ['$.property', {
                    external_uuid: '$.uuid',
                    name: '$.name',
                    street1: '$.street1',
                    street2: '$.street2',
                    city: '$.city',
                    state: '$.state',
                    zip: '$.zip',
                    // equipments: [{
                    //     uuid: "",
                    //     external_uuid: "",
                    //     name: "",
                    //     model_num: "",
                    //     serial_num: "",
                    //     notes: "",
                    //     make: "",
                    //     system_name: "",
                    //     criticality: 3,
                    //     year: "",
                    //     building: "",
                    //     floor: "",
                    //     area: ""
                    // }]
                }]
            }]
        }

        const result = jsonTransform(json, template);
        console.log("ORIGINAL", json);
        console.log("REFORMATTED", result);
        return result;
    }

    const getPredictedSystemTypes = async (json) => {
        if (predictSystemTypes) {
            console.log("predicting system types...")
            const finalProperties = await predictSystemTypeIds(json["companies"][0]["properties"])
            console.log("got predictions:", finalProperties)
            return finalProperties;
        }
        return json;
    }

    const insertPredictions = (json, predictedSystemTypes) => {
        let properties = json.companies[0].properties
        for (let pi = 0;  pi < properties.length; pi++) {
            let equipments = properties[pi].equipments
            for (let ei = 0;  ei < equipments.length; ei++) {
                if (!equipments[ei].system_name || equipments[ei].system_name === "") {
                    const systemsTypeIdLookupKey = '' + predictedSystemTypes[pi][ei].value.system_type_id
                    equipments[ei].system_id = systemTypeIdLookup[systemsTypeIdLookupKey].system_id;
                    equipments[ei].system_name = systemTypeIdLookup[systemsTypeIdLookupKey].name;
                    console.log("predicted", equipments[ei].system_name)
                }
            }
        }
        return properties
    }

    const fixRawJSONEquipment = e => {
        const equipmentMetadata = equipmentProperties();
        Object.keys(e).map((key, i) => {
            if (equipmentMetadata[key] && equipmentMetadata[key].type === 'string'){
                e[key] = `${key in e ? e[key] || '' : ''}`
            }
        })
        return {
            ...e,
            year: `${e.year}`,
            system_name: e.system_id ? systemIdLookup[e.system_id] && systemIdLookup[e.system_id].name : ''
        }
    }

    const fixRawJSONProperty = p => {
        return {
            ...p,
            note: p.note || '',
            external_uuid: p.external_uuid || ''
        }
    }

    const handleJSONRead = async () => {
        const textContent = fileReader.result;
        let json = JSON.parse(textContent);

        if (predictSystemTypes) {
            let predictedSystemTypes = await getPredictedSystemTypes(json);
            setPredictionsCompleted(true)
            json.companies[0].properties = insertPredictions(json, predictedSystemTypes)
        }

        if (json.Contractors !== undefined){ // Bluefin
            json = reformatBluefinJSON(json);
        }

        json.companies = json.companies.map(c => {
            return {
                ...c,
                properties: (c.properties || []).map(p => {
                    return {
                        ...fixRawJSONProperty(p),
                        equipments: (p.equipments || []).map(e => {
                            return {
                                ...fixRawJSONEquipment(e),
                                subcomponents: (e.subcomponents || []).map(c => {
                                    return fixRawJSONEquipment(c)
                                })
                            }
                        })
                    }
                })
            }
        })

        setUploadedFileJSONImport(json);
        setUploadedFileJSON(json);
    }

    const xlsxSheetToAssociativeArray = (sheet) => {
        const equipmentColumns = sheet[0];
        const dataRows = sheet.slice(1);
        const result = dataRows.map((current, index) => {
            return equipmentColumns.reduce((a, x, i) => {
                a[`${x}`] = current[i];
                return a
            }, {})
        })
        
        return result;
    }

    const renameKeys = (keysMap, obj, typeMap = {}) => {
        return Object.keys(obj).reduce((acc, key) => {
            let renamedObject = {};
            if (keysMap[key] === undefined){
                renamedObject = {
                    unmapped_json_import: {...acc.unmapped_json_import, [key]: obj[key]}
                }
            }else{
                // Also convert to String to match manually-entered input into the form
                const newKey = keysMap[key];
                renamedObject = {[newKey]: obj[key] || ''};
                
                if (newKey in typeMap && !Utils.isBlank(typeMap[newKey].type) && !Utils.isBlank(obj[key])){
                    if (typeMap[newKey].type.localeCompare("string") === 0){
                        renamedObject = {[newKey]: `${obj[key] || ''}`};
                    }else  if (typeMap[newKey].type.localeCompare("number") === 0){
                        renamedObject = {[newKey]: obj[key]};
                    }
                }
            }

          return {
              ...acc,
            ...renamedObject
          };
        }, {});
      };

    const mapEquipmentColumnNames = (obj, typeMap = {}) => {
        const columnNameMapping = 
        {
            "WEB EQPT TEMPLATE": "equipments",
            // "PROPERTY UUID": null,
            "PROPERTY UUID (DST USE ONLY)": "uuid",
            "EQUIPMENT UUID": "uuid",
            "EQUIPMENT UUID (DST USE ONLY)": "uuid",
            "EXTERNAL UUID": "external_uuid",
            "EXTERNAL UUID (DST USE ONLY)": "external_uuid",
            "EQUIPMENT NAME": "name",
            "EQUIPMENT or COMPONENT NAME": "name",
            "MAKE": "make",
            "MODEL NUMBER": "model_num",
            "SERIAL NUMBER": "serial_num",
            "YEAR": "year",
            "YEAR INSTALLED (4 digit year)": "year",
            "NOTES": "notes",
            "NOTE": "notes",
            "DST SYSTEM": "system_name",
            "DST SYSTEM (SELECT FROM DROPDOWN)": "system_name",
            "CRITICALITY": "criticality",
            "BUILDING NAME": "building",
            "FLOOR ": "floor",
            "FLOOR": "floor",
            "AREA": "area",
            "AREA SERVED": "area",
            "PARENT EQUIPMENT NAME": "parent_name",
            "PARENT EQUIPMENT NAME (For Components Only)": "parent_name"
            // "AREA SERVED": "area_served",
            // "LOCATION": "location"
            // "EQUIPMENT TYPE": null,
        }
        const renamedObject = renameKeys(columnNameMapping, obj, typeMap)
        return renamedObject;
    }

    const mapPropertyColumnNames = (obj, typeMap = {}) => {
        const columnNameMapping = 
        {
            "WEB PROP TEMPLATE": "properties",
            "EXTERNAL UUID": "external_uuid",
            "EXTERNAL UUID (DST USE ONLY)": "external_uuid",
            "COMPANY UUID": "uuid",
            "COMPANY UUID (DST USE ONLY)": "uuid",
            "PROPERTY UUID": "uuid",
            "PROPERTY UUID (DST USE ONLY)": "uuid",
            "PROPERTY NAME": "name",
            "STREET1": "street1",
            "STREET2": "street2",
            "CITY": "city",
            "STATE": "state",
            "STATE/PROVINCE": "state",
            "ZIP": "zip",
            "ZIP/POSTAL CODE": "zip",
            "COUNTRY": "country",
            "NOTES": "note",
            "NOTE": "note",

        }
        const renamedObject = renameKeys(columnNameMapping, obj, typeMap)
        return renamedObject;
    }
    
    const handleXLSXRead = async () => {
        const textContent = fileReader.result;
        const workbook = XLSX.read(textContent, {type:'binary'});

        // Hardcode names to expect instead of using workbook.SheetNames[0]
        const propertySheetName = "WEB PROP TEMPLATE"
        const equipmentSheetName = "WEB EQPT TEMPLATE"
        if (workbook.SheetNames.length < 2 || !workbook.SheetNames.includes(propertySheetName) || !workbook.SheetNames.includes(equipmentSheetName)){
            toast.error(
                `Validation Error: Selected file does not match the DST template. Please download the template file and modify with your data to import`,
            );
            setLoadingFile(false);
            return;
        }
        
        let properties = [];
        let propertySheet = XLSX.utils.sheet_to_json(workbook.Sheets[propertySheetName], {header:1});
        const associativeProperties = xlsxSheetToAssociativeArray(propertySheet);
        propertySheet = associativeProperties.map(p => mapPropertyColumnNames(p, propertyProperties()));
        console.log("parsed propertySheet", propertySheet)
        properties = propertySheet.filter(p => !Utils.isBlank(p.name))

        let equipmentSheet = XLSX.utils.sheet_to_json(workbook.Sheets[equipmentSheetName], {header:1});
        equipmentSheet = xlsxSheetToAssociativeArray(equipmentSheet);
        console.log("parsed equipmentSheet", equipmentSheet)
        properties.map(async (property, index) => {
            const propertyEquipment = equipmentSheet
                .filter(e => property.name.localeCompare(e["PROPERTY NAME"], undefined, { sensitivity: 'base' }) === 0);
            console.log("propertyEquipment", propertyEquipment)
            const allEquipmentIncludingComponents = propertyEquipment
                .map(e => mapEquipmentColumnNames(e, equipmentProperties()))
                // .filter(e => Utils.isBlank(e.parent_name))
                .map(e => {
                    if (!Utils.isBlank(e.year)){
                        if (e.year.length === 4){
                            const yearNumber = Number.parseInt(e.year);
                            if (yearNumber > 1900 && yearNumber < 2100){
                                return {
                                    ...e,
                                    year: `${yearNumber}`
                                }; 
                            }
                        }else{
                            const equipmentDate = XLSX.SSF.parse_date_code(e.year, {date1904:false})
                            return {
                                ...e,
                                year: `${equipmentDate.y}`
                            };
                        }
                    }
                    return e;
                })

            const propertyRootEquipment = allEquipmentIncludingComponents.filter(e => Utils.isBlank(e.parent_name))
            
            propertyRootEquipment.map((rootEquipment, rootEquipmentIndex) => {
                const equipmentWithRootParentName = allEquipmentIncludingComponents
                    .filter(e => !Utils.isBlank(rootEquipment.name) && (e.parent_name || "").localeCompare(rootEquipment.name, undefined, { sensitivity: 'base' }) === 0);
                propertyRootEquipment[rootEquipmentIndex].subcomponents = equipmentWithRootParentName;
            })

            properties[index].equipments = propertyRootEquipment

        })

        const newUploadFileJSON = {
            companies:[
                {
                    companyDetails: {
                        name: user.company.title || ""
                    },
                    properties
                }
            ]
        };

        if (predictSystemTypes) {
            let predictedSystemTypes = await getPredictedSystemTypes(newUploadFileJSON);
            setPredictionsCompleted(true)
            newUploadFileJSON.companies[0].properties = insertPredictions(newUploadFileJSON, predictedSystemTypes)
        }
        
        setUploadedFileJSON(newUploadFileJSON);
        setLoadingFile(false);
        setLoadedFile(true);
    }

    const ErrorListTemplate = (props) => {
        const { errors } = props;
        return (
            <div className="alert-danger p-3 m-2">
                <h2>Validation Errors</h2>
                <ul>
                    {errors.map(error => (
                        <li key={error.stack}>
                            {error.readableStack}: 
                            <br/>
                            <ul>
                                <li>{error.message}</li>
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    const transformErrors = (errors) => {
        return errors.map(error => {
            const pathSegments = error.property.split('.').slice(1);
            let pathValues = pathSegments.map((pathSegment, i) =>{
                const builtPath = `$.${pathSegments.slice(0,i+1).join('.')}`;
                return jsonpath.query(uploadedFileJSON, builtPath);
            })
            const attributeName = pathSegments.slice(pathSegments.length - 1)
            const attributeValue = pathValues.slice(pathValues.length - 1)
            error.readableStack = 
            [
                pathValues.length > 0 ? `Company(${pathValues[0][0].companyDetails.name || ''})` : null,
                pathValues.length > 1 ? `Property(${pathValues[1][0].name || ''})` : null,
                pathValues.length > 2 ? `Equipment(${pathValues[2][0].name || ''})` : null,
                `Attribute '${attributeName}' ("${attributeValue}")`
            ].filter(x => x != null).join(' -> ')
            return error;
        });
    }

    const onError = errors => {
        if (errors.length > 0){
            window.scrollTo(0, 0)
            toast.error(
                `Validation Error: Check error list for specific validation warnings and recommendations.`,
            );
        }
    }

    const onChange = async data => {
        setUploadedFileJSON(data.formData);
    }

    const getPredictedSystemTypeId = async data => {
        const {name, make, model_num} = data;
        const res = await runQueryNetworkOnly(PredictSystemTypeId, {name:name, make:make, model:model_num, isChildEquipment:false});
        if (res.data.predictSystemTypeId) {
            const predictedSystemTypeId = res.data.predictSystemTypeId
            return predictedSystemTypeId;
        } else { return; }
    }

    const predictSystemTypeIds = async properties => {

        const promises = properties.map(property => {
            return property.equipments.map(equipment => {
                if (!equipment.system_name) {
                    return getPredictedSystemTypeId(equipment)
                }
            })
        })

        let allResults = []

        for (let i=0; i<promises.length; i++) {
            const results = await Promise.allSettled(promises[i])
            allResults.push(results)
        }
        
        return allResults
    }

    const onSubmit = async data => {
        setUploadedFileJSON(data.formData);
        const validationErrors = validateJson(data.formData);
        const isValid = validationErrors.length === 0 && (!predictSystemTypes || predictSystemTypes && predictionsCompleted);
        if (!isValid){
            toast.warning(
                `Import cannot proceed... Please resolve all warnings at the top of the page before re-submitting.`,
                {autoClose: 10000}
            ); 
            return;
        }else{
            toast.info(
                `Import Starting... You will see a green notification when the import is complete.`,
                {autoClose: 10000}
            ); 
            const result = await parseAndImport(data.formData);
            if (result){
                const [createdCompanies, createdProperties, createdEquipments] = result;
                toast.success(
                    `Import Successful.\nImported ${createdCompanies.length} companies, ${createdProperties.length} properties, and ${createdEquipments.length} equipment(s)`,
                    {autoClose: false}
                );
            }
        }
    }

    const onFileChangeHandler = e => {
        const file = e.target.files[0];

        fileReader = new FileReader();
        if (file.name.indexOf(".json") > 0){
            fileReader.onloadend = handleJSONRead;
            fileReader.readAsText(file);
        }else if (file.name.indexOf(".xlsx") > 0){
            setLoadingFile(true);
            fileReader.onloadend = handleXLSXRead;
            fileReader.readAsBinaryString(file);
        }else{
            toast.error(
                `Only Excel files created from a DST template(.xlsx) and DST JSON files (.json) are supported at this time.`,
            );
        }
    }

    const downloadObjectAsJson = (exportObj, exportName) => {
        var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj, null, 4));
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href",     dataStr);
        downloadAnchorNode.setAttribute("download", exportName + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    const uiSchema = {
        "predict_system_type": {
            "ui:widget": "checkboxes"
        }
    }

    const propertyProperties = () => {
        return {
            "uuid": {
                "type": "string",
                "title": "Property UUID",
                "description": "The UUID used to reference this property. A random UUID is generated if omitted.",
                "default": "",
                "maxLength": 36,
                "pattern": "^([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})?$"
            },
            "external_uuid": {
                "type": "string",
                "title": "External UUID",
                "description": "The UUID used to reference this property outside of DST. Used for asset management tracking.",
                "default": "",
                "maxLength": 500,
            },
            "name": {
                "type": "string",
                "title": "Property Name",
                "default": "",
                "maxLength": 500,
                "minLength": 0,
            },
            "street1": {
                "type": "string",
                "title": "Street 1",
                "description": "Street name and number",
                "default": "",
                "maxLength": 500,
            },
            "street2": {
                "type": "string",
                "title": "Street 2",
                "description": "Apartment number, PO box, etc.",
                "default": "",
                "maxLength": 500,
            },
            "city": {
                "type": "string",
                "title": "City",
                "default": "",
                "maxLength": 500,
            },
            "state": {
                "type": "string",
                "title": "State Abbreviation",
                "description": "Two letter State abbreviation - NC, TN, etc.",
                "default": "",
                "maxLength": 2
                // "pattern": "^(A[LKSZRAEP]|C[AOT]|D[EC]|F[LM]|G[AU]|HI|I[ADLN]|K[SY]|LA|M[ADEHINOPST]|N[CDEHJMVY]|O[HKR]|P[ARW]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY])?$"
            },
            "country": {
                "type": "string",
                "title": "Country",
                "default": "United States"
            },
            "zip": {
                "type": "string",
                "title": "Zip Code",
                "default": "",
                "maxLength": 10
                // "pattern": "^(\\d{5}(-\\d{4})?)?$"
            },
            "note": {
                "type": "string",
                "title": "Notes",
                "default": "",
                "maxLength": 50000,
            },
            "equipments": {
                "description": "List of equipment to add if they don't already exist.",
                "title": "Equipment List",
                "additionalItems": false,
                "type": "array",
                "items": {
                    "type": "object",
                    "properties": equipmentProperties()
                }
            }
        }
    }

    const equipmentProperties = (depth = 0) => {
        if (depth === (COMPONENT_DEPTH_LIMIT + 1)){
            return null;
        }
        const isChild = depth > 0;
        const typeName = isChild ? `Component` : "Equipment";

        return {
            "uuid": {
                "type": "string",
                "title": `${typeName} UUID`,
                "description": "The UUID used to reference this equipment. A random UUID is generated if omitted.",
                "default": "",
                "maxLength": 36,
                "pattern": "^([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})?$"
            },
            "external_uuid": {
                "type": "string",
                "title": `External ${typeName} UUID`,
                "description": "The UUID used to reference this equipment outside of DST. Used for asset management tracking.",
                "default": "",
                "maxLength": 500,
            },
            "name": {
                "type": "string",
                "title": `${typeName} Name`,
                "description": "A name for this equipment. DST recommends using all uppercase, distinct names.",
                "default": "",
                "maxLength": 500,
                "minLength": 1,
            },
            "model_num": {
                "type": "string",
                "title": "Model Number",
                "description": "The model number for this equipment",
                "default": "",
                "maxLength": 500,
            },
            "serial_num": {
                "type": "string",
                "title": "Serial Number",
                "description": "The serial number for this equipment",
                "default": "",
                "maxLength": 500,
            },
            "notes": {
                "type": "string",
                "title": "Notes",
                "description": "Any notes specific to this equipment",
                "default": "",
                "maxLength": 50000,
            },
            "make": {
                "type": "string",
                "title": "Make",
                "description": "The make of this equipment",
                "default": "",
                "maxLength": 500,
            },
            "system_name": {
                "type": "string",
                "title": "System",
                "description": "The system type of this equipment",
                "default": "",
                // "required": true,
                "enum": systemNameLookup ? Object.keys(systemNameLookup) : []

            },
            "criticality": {
                "type": "number",
                "title": "Criticality",
                "description": "The criticality score for this equipment",
                "enum": [1, 2, 3, 4, 5],
                "default": 3
            },
            "year": {
                "type": "string",
                "title": "Year",
                "default": "",
                "pattern": "^[0-9]{0,5}$",
                "maxLength": 500,
                "description": "Year of manufacture"
            },
            "building": {
                "type": "string",
                "title": "Building",
                "default": "",
                "maxLength": 500,
                "description": "Specific building location"
            },
            "floor": {
                "type": "string",
                "title": "Floor",
                "default": "",
                "maxLength": 500,
                "description": "Specific floor location"
            },
            "area": {
                "type": "string",
                "title": "Area",
                "default": "",
                "maxLength": 500,
                "description": "Specific area location"
            },
            "subcomponents": depth === COMPONENT_DEPTH_LIMIT ? {} : {
                "description": "List of components to add if they don't already exist.",
                "title": `Component List (Level ${depth + 1})`,
                "additionalItems": false,
                "type": "array",
                "items": {
                    "type": "object",
                    "properties": equipmentProperties(depth + 1)
                }
            }
        }
    };
    
    const validationSchema = () => {
        return {
            "$id": "http://example.com/example.json",
            "$schema": "http://json-schema.org/draft-07/schema",
            "default": {},
            "required": [
                "companies"
            ],
            "type": "object",
            "properties": {
                "companies": {
                    "default": [],
                    "description": "List of companies to add if they don't already exist.",
                    "title": "Company List",
                    "additionalItems": false,
                    "type": "array",
                    "items": {
                        "title": "Company",
                        "type": "object",
                        "properties":
                        {
                            "companyDetails": {
                                "title": "",
                                "type": "object",
                                "anyOf": [
                                    {
                                        "title": "Existing Company",
                                        "properties": {
                                            "name": {
                                                "title": "Company Name",
                                                "type": "string",
                                                "description": "",
                                                "default": companies && companies.length > 0 ? companies[0].title : "",
                                                "enum": companies ? companies.map(c => c.title) : [],
                                                "disabled": companies.length === 1 ? true : false
                                            }
                                        },
                                        "required": [
                                            "name"
                                        ]
                                    },
                                    disableNewCompany ? null : {
                                        "title": "New Company",
                                        "properties": {
                                            "name": {
                                                "type": "string",
                                                "title": "Company Name",
                                                "default": "",
                                                "minLength": 1,
                                                "maxLength": 500
                                            },
                                            "external_uuid": {
                                                "type": "string",
                                                "title": "Company External UUID",
                                                "description": "The UUID used to reference this company outside of DST.",
                                                "default": "",
                                                "maxLength": 500,
                                            },
                                        },
                                        "required": [
                                            "name"
                                        ]
                                    }
                                ].filter(x => x!==null)
                            },
    
                            "properties": {
                                "description": "List of properties to add if they don't already exist.",
                                "title": "Property List",
                                "additionalItems": false,
                                "type": "array",
                                "items": {
                                    "type": "object",
                                    "properties": propertyProperties()
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return (
        <div className="d-flex flex-row justify-content-between flex-sm-row">
            <NavSidebar/>
            <div className="d-flex flex-column rounded-lg bg-white flex-grow-1 p-3"  style={{overflow: 'hidden', margin: "10px 10px 10px 10px"}}>
                {/* <div className="d-flex flex-row justify-content-center rounded-lg pt-2 mb-3" style={{backgroundColor: "rgb(250,80,10)"}}>
                    <div className="flex-column align-items-center justify-content-start">
                        <h2>This section is under construction. To try this feature please contact DST at <a href="tel:+18009571424">1-800-957-1424</a></h2>
                    </div> 
                </div> */}
                <div className="flex-column">
                        <h3>Select a file to import<span className="text-black" style={{fontSize: '10px'}}>&nbsp;(v0.92)</span></h3>
                        <ul>
                            {/* <li>Only supports creating new companies/properties/equipment/components.</li> */}
                            <li>Please review the loaded data before submitting.</li>
                            {/* <li>Imports into the logged in users company by default</li> */}
                            <li>Data will be imported to the user's company.</li>
                            <li><a href="/IMPORT_TEMPLATE_v92.xlsx" target="_blank" rel="noopener noreferrer">Download DST Import Template</a></li>
                            <li><a href="/IMPORT_TEMPLATE_EXAMPLE.xlsx" target="_blank" rel="noopener noreferrer">Download Example Import File</a></li>
                            <li><a href="/DST_IMPORTER_TRAINING_v1.pdf" target="_blank" rel="noopener noreferrer">Download DST Importer Training Guide</a></li>
                            <li><a href="/SYSTEM_TYPES.pdf" target="_blank" rel="noopener noreferrer">Download System Type Guide</a></li>
                        </ul>
                        
                    <div className="flex-row my-4">
                        <input
                            type="file"
                            name="Import File"
                            accept="*.xlsx, *.xls, *.json, *.XLSX, *.XLS"
                            multiple={false}
                            onChange={onFileChangeHandler}
                        />
                        {loadingFile && <ImSpinner3 className="icon-spin ml-3" style={{fontSize: 20}}/>}
                    </div>
                    <div className="flex-row my-4">
                        {/* <input
                            type="checkbox"
                            name="Predict System Types"
                            value={predictSystemTypes}
                            onChange={() => {setPredictSystemTypes(!predictSystemTypes)}}
                        />
                        <label className='pl-1 pr-3' >Predict System Types</label> */}
                        {isSuperAdmin() && (
                            <>
                                <input
                                    type="checkbox"
                                    name="Manual Edit Mode"
                                    value={manualEditMode}
                                    onChange={() => {
                                        setManualEditMode(!manualEditMode)
                                    }}
                                />
                                <label className='pl-1' >Manually Edit Import</label>
                            </>
                        )}

                    </div>

                    
                    <div className="flex-row">
                        {errorLog.length > 0 &&
                            <div>
                                <h4><img src="/icons/red-stop-sign.svg" style={{width: "25px", height: "25px"}}/> Import Warnings:</h4>
                                <ul>
                                    {errorLog && errorLog.map(error => <li>{error.message}</li>)}
                                </ul>
                                <br/>
                            </div>
                        }

                        {!Utils.isBlank(uploadedFileJSON) && !manualEditMode &&
                        <div>
                            <ul>
                                {(uploadedFileJSON.companies || []).map(company => {
                                    return (
                                        <ul className="list-group">
                                            <li className="list-group-item">Company: {company.companyDetails.name}</li>
                                            <ul>
                                                {(company.properties || []).map(property => {
                                                    return (
                                                        <>
                                                            <li className="list-group-item">
                                                                Property: {property.name}
                                                                <span className="badge badge-dark float-right">{(property.equipments || []).length || 0} Root Equipment</span>
                                                            </li>
                                                            <ul>
                                                                {(property.equipments || []).map(equipment => {
                                                                    const numSubcomponentsCount = (equipment.subcomponents || []).length || 0;
                                                                    return (
                                                                        <>
                                                                            <li className="list-group-item">
                                                                                Equipment: {equipment.name}
                                                                                {/* {equipment.system_type_predicted ? <span className="badge float-right">{equipment.system_id}Predicted: {(allSystems[equipment.system_id] || {}).name}</span> : <></>} */}
                                                                                {/* {equipment.system_type_predicted ? <span className="badge float-right">Predicted System: {systemsIdLookup[equipment.system_id].name}</span> : null} */}
                                                                                {systemNameLookup && systemNameLookup[equipment.system_name] && equipment.system_name ? 
                                                                                    <span className="badge badge-success float-right ml-4">
                                                                                        {systemNameLookup[equipment.system_name].name}
                                                                                    </span>
                                                                                :
                                                                                    <span className="badge badge-danger float-right ml-4">
                                                                                        Missing valid System
                                                                                    </span>
                                                                                }
                                                                                <span className="badge badge-dark float-right">{numSubcomponentsCount > 0 ? `${numSubcomponentsCount} Components` : ``}</span>
                                                                            </li>
                                                                            {numSubcomponentsCount > 0 &&
                                                                                <ul>
                                                                                    {(equipment.subcomponents || []).map(component => {
                                                                                        return (
                                                                                            <li className="list-group-item">
                                                                                                Component: {component.name}
                                                                                                {/* {component.system_type_predicted ? <span className="badge float-right">Predicted: {(allSystems[component.system_id] || {}).name}</span> : <></>} */}
                                                                                                {/* {component.system_type_predicted ? <span className="badge float-right">Predicted System: {systemsIdLookup[component.system_id].name}</span> : null} */}
                                                                                                {/* <span className="badge badge-info float-right">
                                                                                                    {systemsLookup && systemsLookup[component.system_name] && systemsLookup[component.system_name].name}
                                                                                                </span> */}
                                                                                                {systemNameLookup && systemNameLookup[component.system_name] && component.system_name ? 
                                                                                                    <span className="badge badge-success float-right ml-4">
                                                                                                        {systemNameLookup[component.system_name].name}
                                                                                                    </span>
                                                                                                :
                                                                                                    <span className="badge badge-danger float-right ml-4">
                                                                                                        Missing valid System
                                                                                                    </span>
                                                                                                }
                                                                                            </li>
                                                                                        )
                                                                                    })}
                                                                                </ul>
                                                                            }
                                                                        </>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </> 
                                                    )
                                                })}
                                            </ul>
                                        </ul>
                                    )
                                })}
                             </ul>
                        </div>
                        }

                        {!Utils.isBlank(uploadedFileJSON) && manualEditMode &&
                            <Form 
                                schema={validationSchema()}
                                transformErrors={transformErrors}
                                uiSchema={{}}
                                formData={uploadedFileJSON}
                                onChange={onChange}
                                showErrorList={true}
                                // extraErrors={extraErrors}
                                // validate={validate}
                                onSubmit={onSubmit}
                                onError={onError}
                                ErrorList={ErrorListTemplate} 
                                children={true} //hide submit button
                            />
                        }

                        {(loadedFile || manualEditMode )&& 
                            <button
                                type="button"
                                className="btn btn-primary p-2 m-2"
                                onClick={() => onSubmit({formData: uploadedFileJSON})}
                            >Submit</button>
                        }

                        {!Utils.isBlank(uploadedFileJSON) && manualEditMode &&
                            <button
                                type="button"
                                className="btn btn-link p-0 my-2"
                                onClick={() => downloadObjectAsJson(uploadedFileJSON, "dst-import")}
                            >Save changes without importing</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ImportScreen;


