import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns'; // Import the date adapter

const FigHealthScoreModal = ({ show, onHide, healthScores }) => {
  const data = {
    labels: healthScores.map(score => new Date(score.created_at)),
    datasets: [
      {
        label: 'Fig Health Score',
        data: healthScores.map(score => score.score),
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: 'rgba(75, 192, 192, 0.2)',
        tension: 0.1, // Add smooth curves to the line
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allow the chart to resize
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
        },
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Score',
        },
        beginAtZero: true, // Ensure the y-axis starts at zero
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
  };

  return (
    <Modal show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header className="justify-content-center">
        <Modal.Title>Health Score</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: '400px' }}> {/* Set a fixed height for the chart */}
        <Line data={data} options={options} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

FigHealthScoreModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  healthScores: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string.isRequired,
      score: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default FigHealthScoreModal;